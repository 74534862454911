import tw, { css, styled } from 'twin.macro'

import { Dropdown } from '../dropdown/dropdown'

export const StyledPagination = styled.span(() => [
  tw`mb-4 flex w-full items-end justify-end rounded-lg border-monochrome-50 bg-white p-2 py-3 pl-6 shadow-md sm:(mb-0 rounded-t-none) mobile:shadow`,
])

export const StyledPaginationDropdown = styled(Dropdown)(() => [
  css`
    & [class*='StyledDropdownButton'] {
      ${tw`-ml-3 justify-end border-none bg-transparent text-xs text-monochrome-700 sm:-ml-6`}
    }
    & [class*='StyledCaretDown'] {
      ${tw`ml-0 shrink-0 sm:ml-1`}
    }
  `,
])

export const StyledPaginationButton = styled.button(() => [tw`h-8 w-8 text-monochrome-900`])
