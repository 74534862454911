import throttle from 'lodash/throttle'
import { useEffect } from 'react'

import { UseInfiniteScrollProps } from './useInfiniteScroll.types'

export const useInfiniteScroll = ({ ref, func, threshold }: UseInfiniteScrollProps) => {
  useEffect(() => {
    const scrollThreshold = threshold || 250
    const onScroll = throttle((e) => {
      if (e.target.scrollTop + e.target.clientHeight + scrollThreshold >= e.target.scrollHeight) {
        if (func && typeof func === 'function') {
          func()
        }
      }
    }, 200)
    const element = ref?.current
    element?.addEventListener('scroll', onScroll)
    return () => {
      element?.removeEventListener('scroll', onScroll)
    }
  }, [ref, func, threshold])

  return null
}
