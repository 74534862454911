import MUIAvatar, { AvatarProps as MUIAvatarProps } from '@mui/material/Avatar'
import MUIAvatarGroup, { AvatarGroupProps } from '@mui/material/AvatarGroup'
import tw from 'twin.macro'

interface AvatarProps extends MUIAvatarProps {
  /**
   * The size of the avatar based on the design system. "large" is the default MUI size.
   */
  size?: 'small' | 'medium' | 'large'
}

export const Avatar = ({ size = 'large', ...props }: AvatarProps) => {
  return (
    <MUIAvatar
      {...props}
      css={[size === 'medium' && tw`(h-9 w-9)!`, size === 'small' && tw`(h-7 w-7 text-xs)!`]}
    />
  )
}

export const AvatarGroup = (props: AvatarGroupProps) => {
  return <MUIAvatarGroup {...props} />
}
