import { useOnClickOutside } from '@cart/ui/hooks/useOnClickOutside'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CSSObject, SubMenu as ReactProSidebarSubMenu } from 'react-pro-sidebar'
import tw from 'twin.macro'

import { Icon } from '../Icon/Icon'
import { MenuItem } from '../MenuItem/MenuItem'
import { IMenuItem } from '../MenuItem/MenuItem.types'
import { useLeftNavStore } from '../../store/left-nav.store'

export const SubMenu = ({ submenu, level = 0 }: { submenu: IMenuItem, level: number }) => {
  const { isCollapsed } = useLeftNavStore()

  const [showSubmenuItems, setShowSubmenuItems] = useState<boolean>(false)
  const menuRef = useRef(null)

  const { id, icon, title, items } = submenu

  const closeSubmenuItems = () => setShowSubmenuItems(false)

  const hideWhenCollapsed = useCallback(() => {
    if (isCollapsed) setShowSubmenuItems(false)
  }, [isCollapsed])
  useOnClickOutside(menuRef, hideWhenCollapsed)

  const isOnChildRoute = items.some(m => m.isActive)

  useEffect(() => {
    if (isOnChildRoute) setShowSubmenuItems(true)
  }, [isOnChildRoute])

  useEffect(() => {
    if (isCollapsed) setShowSubmenuItems(false)
    else if (!isCollapsed && isOnChildRoute) setShowSubmenuItems(true)
  }, [isCollapsed, isOnChildRoute])

  const onSubmenuClick = () => {
    setShowSubmenuItems((prev) => !prev)
  }

  const iconComp = useMemo(
    () =>
      level === 0 && (
        <Icon
          icon={icon as IconName}
          hidden={!icon}
          title={title}
          showTooltip={isCollapsed && !showSubmenuItems}
        />
      ),
    [isCollapsed, showSubmenuItems, icon, level, title],
  )

  let submenuStyles: CSSObject
  if (isCollapsed && !showSubmenuItems) submenuStyles = tw`[& .ps-submenu-content]:!invisible`
  else if (showSubmenuItems) submenuStyles = tw`[& .ps-submenu-content]:visible`

  return (
    <ReactProSidebarSubMenu
      ref={menuRef}
      label={title}
      defaultOpen={isOnChildRoute}
      open={showSubmenuItems}
      icon={iconComp}
      data-level={level}
      onClick={onSubmenuClick}
      rootStyles={submenuStyles}
      className={isOnChildRoute && isCollapsed ? 'icon-active' : ''}
    >
      {items.map((item: IMenuItem) => {
        if (item.hidden) return null
        if (!item.url && !item.onClick) {
          return isCollapsed ? null : (
            <h5 key={`${id}:${item.title}`} className="leftnav-group-header">
              {item.title}
            </h5>
          )
        }
        return (
          <MenuItem
            key={`${id}:${item.title}`}
            item={item}
            level={level + 1}
            closeParentMenu={hideWhenCollapsed}
          />
        )
      })}
    </ReactProSidebarSubMenu>
  )
}
