import { ComponentClass, FunctionComponent, ReactNode } from 'react'

export type LinkComponentType =
  | string
  | FunctionComponent<{
      key?: string
      href?: string
      target?: string
      rel?: string
      children?: ReactNode
    }>
  | ComponentClass<{
      key?: string
      href?: string
      target?: string
      rel?: string
      children?: ReactNode
    }>
