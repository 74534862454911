import React, { useState } from 'react'

import formMethods from '../../framework/forms/form-methods'
import {
  StyledAriaSpan,
  StyledInnerLabelText,
  StyledOuterLabelText,
  StyledSwitch,
  StyledToggleButton,
  StyledToggleWrapper,
} from './toggle.styles'
import { ToggleProps } from './toggle.types'

export const Toggle = ({
  enabledText,
  disabledText,
  labelPosition = 'outside',
  variant = 'medium',
  id,
  className,
  checked,
  onToggle,
}: ToggleProps) => {
  const [enabled, setEnabled] = useState(checked !== undefined ? checked : false)
  const { register, setValue } = formMethods()
  const registerField = register(id)

  const handleToggle = () => {
    if (onToggle) onToggle(!enabled)
    setEnabled(($enabled) => {
      setValue(id, `${!$enabled}`)
      return !$enabled
    })
  }

  return (
    <StyledToggleWrapper className={className}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading  */}
      <input id={id} type="hidden" value={`${enabled}`} {...registerField} />
      <StyledSwitch
        checked={enabled}
        variant={variant}
        labelPosition={labelPosition}
        onChange={handleToggle}
        data-testid="toggle"
      >
        <StyledAriaSpan>Use setting</StyledAriaSpan>
        {enabled && enabledText && variant !== 'small' && labelPosition === 'inside' && (
          <StyledInnerLabelText checked={enabled}>{enabledText}</StyledInnerLabelText>
        )}
        <StyledToggleButton
          aria-hidden="true"
          checked={enabled}
          variant={variant}
          labelPosition={labelPosition}
        />
        {!enabled && disabledText && variant !== 'small' && labelPosition === 'inside' && (
          <StyledInnerLabelText checked={enabled}>{disabledText}</StyledInnerLabelText>
        )}
      </StyledSwitch>

      {enabled && enabledText && variant !== 'small' && labelPosition === 'outside' && (
        <StyledOuterLabelText>{enabledText}</StyledOuterLabelText>
      )}
      {!enabled && disabledText && variant !== 'small' && labelPosition === 'outside' && (
        <StyledOuterLabelText>{disabledText}</StyledOuterLabelText>
      )}
    </StyledToggleWrapper>
  )
}
