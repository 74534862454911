export const roleListTestData = {
  id: 1,
  data: [
    {
      id: 2,
      displayName: 'Organization Admin',
      description: 'Org admin. Has all permissions within an organization.',
      name: 'org_admin',
      __typename: 'Role',
    },
    {
      id: 3,
      displayName: 'Business Admin',
      description: 'Business admin. Can request services and manage users for a business.',
      name: 'business_admin',
      __typename: 'Role',
    },
    {
      id: 7,
      displayName: 'Channel Admin',
      description: 'Channel admin. Can request services and manage users for a channel.',
      name: 'channel_admin',
      __typename: 'Role',
    },
    {
      id: 14,
      displayName: null,
      description: null,
      name: 'business_member_test',
      __typename: 'Role',
    },
    {
      id: 1,
      displayName: 'Superadmin',
      description: 'Superuser. Restricted to Cart admin employees.',
      name: 'superadmin',
      __typename: 'Role',
    },
    {
      id: 11,
      displayName: 'Customer Sales Representative',
      description: 'Customer Sales Rep role for assistance with orgs/businesses/user management.',
      name: 'customer_sales_rep',
      __typename: 'Role',
    },
    {
      id: 9,
      displayName: 'Sales Channel Admin',
      description: 'Sales Channel admin. Can request services and manage users for a salesChannel.',
      name: 'salesChannel_admin',
      __typename: 'Role',
    },
    {
      id: 6,
      displayName: 'Member',
      description: 'Member. Can view enabled services.',
      name: 'member',
      __typename: 'Role',
    },
    {
      id: 12,
      displayName: 'Organization Member',
      description: 'Organization Member. Can view enabled services.',
      name: 'org_member',
      __typename: 'Role',
    },
    {
      id: 13,
      displayName: 'Business Member',
      description: 'Business Member. Can view enabled services.',
      name: 'business_member',
      __typename: 'Role',
    },
    {
      id: 623,
      displayName: 'Unified Analytics Brand Admin',
      description: 'Can access all UA functionality other than Provisioning Service',
      name: 'ua_brand_admin',
      __typename: 'Role',
    },
    {
      id: 624,
      displayName: 'Unified Analytics Marketing Agency',
      description: 'Marketing Agency user. Currently same experience as Manager',
      name: 'ua_marketing_agency',
      __typename: 'Role',
    },
    {
      id: 8,
      displayName: 'Store Admin',
      description: 'Store admin. Can request services and manage users for a store.',
      name: 'store_admin',
      __typename: 'Role',
    },
    {
      id: 625,
      displayName: 'Unified Analytics Viewer',
      description:
        'Can see visualizations and connector status. Can’t create/update/delete connectors, can’t access custom views',
      name: 'ua_viewer',
      __typename: 'Role',
    },
    {
      id: 626,
      displayName: 'Unified Analytics Fulfillment Viewer',
      description: 'A customer user that can only access Fulfillment views',
      name: 'ua_fulfillment_viewer',
      __typename: 'Role',
    },
  ],
  __typename: 'PaginatedRole',
} as const
