import { TimeUnits } from './timeSince.constants'

/**
 * Get a short human readable string of the time passed since the given date.
 * For example, 1h, 2w, 3y...
 * If the date is invalid an empty string will be returned.
 * @param date  The starting point from which the elapsed time will be calculated.
 * @returns short time since string or empty string.
 */
export function timeSince(date?: Date | string): string {
  if (!date) return ''

  const startDate = typeof date === 'string' ? new Date(date) : date

  if (startDate.toString() === 'Invalid Date') {
    return ''
  }

  const currentDate = new Date()
  const timeDiff = currentDate.getTime() - startDate.getTime()
  const minutes = Math.floor(timeDiff / TimeUnits.Minutes)
  const hours = Math.floor(timeDiff / TimeUnits.Hours)
  const days = Math.floor(timeDiff / TimeUnits.Days)
  const weeks = Math.floor(timeDiff / TimeUnits.Weeks)
  const months = Math.floor(timeDiff / TimeUnits.Months)
  const years = Math.floor(timeDiff / TimeUnits.Years)

  if (years > 0) {
    return `${years}y`
  }
  if (months > 0) {
    return `${months}m`
  }
  if (weeks > 0) {
    return `${weeks}w`
  }
  if (days > 0) {
    return `${days}d`
  }
  if (hours > 0) {
    return `${hours}h`
  }
  if (minutes > 1) {
    return `${minutes}m`
  }
  return `just now`
}
