export interface Business {
  id: number
  name: string
  roleName: string
}

export interface Organization {
  id: number
  name: string
  businesses: Business[]
  businessEntityTerminology?: string
  roleName: string
}

export interface MeUser {
  id: number
  auth0Id: string
  firstName?: string
  lastName?: string
  primaryEmail: string
  createdAt: Date
  userData: {
    phone: string
    company: string
  }
  userAppSettings: {
    app: string
    setting: string
    type: string
  }[]
  organizations: Organization[]
}

export interface MeResponse {
  me: {
    user: MeUser
  }
}
