import { StorefrontAccountsList, StorefrontProvisioningAccount } from '@brand-console/types'

export interface IAppStore {
  showFooter: boolean
  showSidebar: boolean
}

export interface ServiceProvisioning {
  provisioned: boolean
}

export interface SFProvisioning extends ServiceProvisioning {
  metadata?: StorefrontAccountsList
  provisioningAccount?: StorefrontProvisioningAccount
}
