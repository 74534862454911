import { LoadingButtonClasses, LoadingButtonProps } from '@mui/lab/LoadingButton'
import { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { IconButtonProps } from '@mui/material/IconButton'

import { PaletteColors } from '../../framework/ThemeProvider/ThemeProvider.types'

export interface ButtonProps
  extends Omit<MuiButtonProps, 'color'>,
    Omit<LoadingButtonProps, 'color'>,
    Omit<IconButtonProps, 'color'> {
  color?: PaletteColors
  loading?: boolean
  classes?: Partial<LoadingButtonClasses>
  iconButton?: boolean
  $contrastText?: string
  /**
   * Use in conjunction with the `LinkComponent={Link}` prop to render a react-router `Link` instead of a `button` element.
   */
  to?: string
  target?: string
  rel?: string
  /** Use in conjunction with icon button to create padded square buttons. */
  square?: boolean
}
