import { FormControlLabelProps, RadioProps } from '@mui/material'
import { RadioGroupProps as MuiRadioGroupProps } from '@mui/material/RadioGroup'
import { UseFormReturn } from 'react-hook-form'

export interface RadioGroupProps extends MuiRadioGroupProps {
  id: string
  methods?: UseFormReturn<any>
  rules?: Record<string, any>
}

export interface RadioItemProps extends Omit<FormControlLabelProps, 'control'> {
  id: string
  radioProps?: RadioProps
}
