export interface TablePaginationProps {
  renderRoot?: HTMLElement
  /**
   * A number that represents the amount of elements to be displayed per page.
   * Should be 5, 10, 25, 50, or 100
   */
  pageSize: number
  /**
   * A callback function to update the page size number.
   */
  pageSizeCallback: (arg0: number) => void
  /**
   * A callback function to update which page is currently rendered.
   */
  currentPageCallback: (arg0: number) => void
  /**
   * A number that represents the total amount of elements in the data set.
   */
  totalCount: number
  /**
   * A number that represents the page that is currently rendered for custom use.
   */
  currentPage?: number
  [x: string]: unknown
}
