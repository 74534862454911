import { ApolloError } from '@apollo/client'
import { AssignedRoleCreateMutation } from '@brand-console/generated-graphql-hooks'
import { OrgRoles } from '@brand-console/types'

export interface BaseAssignRoleProps {
  orgId: number
  userId: number
  role: string
  onCompleted?: (data: AssignedRoleCreateMutation) => void
  onError?: (error: ApolloError | Error) => void
}

export interface OrgAssignRoleProps extends BaseAssignRoleProps {
  businessId?: number
  role: OrgRoles
}

export interface BusinessAssignRoleProps extends BaseAssignRoleProps {
  businessId: number
}

export type AssignRoleProps = OrgAssignRoleProps | BusinessAssignRoleProps
