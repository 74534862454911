import TextField from '@mui/material/TextField'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker'
import React, { FC, useState } from 'react'
import { useController, Validate } from 'react-hook-form'

import formMethods from '../../framework/forms/form-methods'
import { useBreakpoint } from '../../framework/hooks/useBreakpoint'
import { DatePickerProps, DatePickerType } from './date-picker.types'

export * from './date-picker.types'

export const DatePicker: FC<DatePickerProps> = ({
  className,
  defaultValue,
  disabled,
  id,
  label,
  rangeLabels = {
    start: 'Start',
    end: 'End',
    to: 'to',
  },
  required,
  readOnly = false,
  type = DatePickerType.DATE_PICKER,
}) => {
  const { control } = formMethods()
  const {
    field: { onChange },
    formState: { errors },
    fieldState: { invalid },
  } = useController({
    control,
    name: id,
    rules: {
      required: required ? 'This field is required' : undefined,
      validate: (value: Validate<any> | Record<string, Validate<any>> | undefined) => {
        let option
        switch (type) {
          case DatePickerType.TIME_PICKER:
            option = 'time'
            break
          case DatePickerType.DATE_TIME_PICKER:
            option = 'date and time'
            break
          case DatePickerType.RANGE_PICKER: {
            option = 'range'
            if (typeof value === 'string' && (value as string).length === 10) {
              const isValidDate = !Number.isNaN(Date.parse(value))
              return isValidDate ? true : `Please enter a valid date`
            }
            const dateRanges = Object.values(value as any)
            const [datetimeStart, datetimeEnd] = dateRanges
            if (
              dateRanges.find((o: any) => o?.toString() === 'Invalid Date') ||
              dateRanges.filter((o) => !o).length === 1 ||
              (datetimeStart as Date) > (datetimeEnd as Date)
            ) {
              return `Please enter a valid range`
            }
            break
          }
          case DatePickerType.DATE_PICKER:
          default:
            option = 'date'
            break
        }
        return value?.toString() !== 'Invalid Date' ? true : `Please enter a valid ${option}`
      },
    },
  })

  const [value, setValue] = useState<Date | null>(defaultValue || null)
  const [rangeValue, setRangeValue] = useState<DateRange<Date>>([null, null])
  const { end, start, to } = rangeLabels
  const isSmall = useBreakpoint('sm')

  let Element:
    | typeof MuiDatePicker
    | typeof TimePicker
    | typeof MobileDatePicker
    | typeof MobileDateTimePicker = MuiDatePicker
  let ResponsiveRangeElement: typeof DateRangePicker | typeof MobileDateRangePicker =
    DateRangePicker

  const fieldLabel =
    label && label !== ''
      ? label
      : (() => {
          let $label
          switch (type) {
            case DatePickerType.TIME_PICKER:
              $label = 'Select Time'
              break
            case DatePickerType.DATE_TIME_PICKER:
              $label = 'Select Date and Time'
              break
            case DatePickerType.DATE_PICKER:
            default:
              $label = 'Select Date'
              break
          }
          return $label
        })()

  const handleChange = (newValue: Date | null) => {
    onChange(newValue)
    setValue(newValue)
  }

  if (typeof isSmall === 'undefined') {
    return null
  }

  switch (type) {
    case DatePickerType.TIME_PICKER:
      Element = isSmall ? TimePicker : MobileTimePicker
      break
    case DatePickerType.DATE_TIME_PICKER:
      Element = isSmall ? DateTimePicker : MobileDateTimePicker
      break
    case DatePickerType.RANGE_PICKER:
      ResponsiveRangeElement = isSmall ? DateRangePicker : MobileDateRangePicker
      break
    case DatePickerType.DATE_PICKER:
    default:
      Element = isSmall ? MuiDatePicker : MobileDatePicker
      break
  }

  const helperText = invalid ? errors[id]?.message : undefined

  return (
    <>
      {type === DatePickerType.DATE_PICKER && (
        <Element
          className={className}
          disabled={disabled}
          label={fieldLabel}
          onChange={handleChange}
          readOnly={readOnly}
          value={value}
          renderInput={(params) => (
            <TextField
              autoComplete="off"
              helperText={helperText}
              {...params}
              required={required}
              error={invalid}
            />
          )}
        />
      )}
      {type === DatePickerType.TIME_PICKER && (
        <Element
          className={className}
          disabled={disabled}
          label={fieldLabel}
          onChange={handleChange}
          readOnly={readOnly}
          value={value}
          renderInput={(params) => (
            <TextField
              autoComplete="off"
              helperText={helperText}
              {...params}
              required={required}
              error={invalid}
            />
          )}
        />
      )}
      {type === DatePickerType.DATE_TIME_PICKER && (
        <Element
          className={className}
          disabled={disabled}
          label={fieldLabel}
          onChange={handleChange}
          readOnly={readOnly}
          value={value}
          renderInput={(params) => (
            <TextField
              autoComplete="off"
              helperText={helperText}
              {...params}
              required={required}
              error={invalid}
            />
          )}
        />
      )}
      {type === DatePickerType.RANGE_PICKER && (
        <ResponsiveRangeElement
          className={className}
          disabled={disabled}
          readOnly={readOnly}
          value={rangeValue}
          onChange={(newValue) => {
            onChange(newValue)
            setRangeValue(newValue)
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField
                tw="w-full"
                {...startProps}
                autoComplete="off"
                label={start}
                required={required}
                helperText={helperText}
                error={invalid}
                onChange={(newValue) => {
                  onChange(newValue)
                }}
              />
              <div tw="mx-2">{to}</div>
              <TextField
                tw="w-full"
                {...endProps}
                autoComplete="off"
                label={end}
                helperText={helperText}
                required={required}
                error={invalid}
                onChange={(newValue) => {
                  onChange(newValue)
                }}
              />
            </>
          )}
        />
      )}
    </>
  )
}
