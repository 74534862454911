import { useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'rooks'

import { IUseScrollPosition } from './useScrollPosition.types'

export const useScrollPosition = (element?: HTMLElement | null) => {
  const containerElement = element || document.body
  const [scrollPosition, setScrollPosition] = useState<IUseScrollPosition>({
    horizontalStart: true,
    verticalStart: true,
    left: 0,
    top: 0,
    horizontalEnd: false,
    verticalEnd: false,
    isScrollable: false,
  })
  const setValueDebounced = useDebounce(setScrollPosition, 100)

  const updatePosition = useCallback(() => {
    setValueDebounced({
      horizontalStart: containerElement.scrollLeft === 0,
      verticalStart: containerElement.scrollTop === 0,
      left: containerElement.scrollLeft,
      top: containerElement.scrollTop,
      horizontalEnd:
        containerElement.scrollLeft + containerElement.clientWidth >= containerElement.scrollWidth,
      verticalEnd:
        Math.ceil(containerElement.scrollTop + containerElement.clientHeight) >=
        containerElement.scrollHeight,
      isScrollable: containerElement.scrollHeight > containerElement.clientHeight,
    })
  }, [
    containerElement.clientHeight,
    containerElement.clientWidth,
    containerElement.scrollHeight,
    containerElement.scrollLeft,
    containerElement.scrollTop,
    containerElement.scrollWidth,
    setValueDebounced,
  ])

  useEffect(() => {
    if (containerElement) {
      containerElement.addEventListener('scroll', updatePosition)
    }
    return () => containerElement.removeEventListener('scroll', updatePosition)
  }, [containerElement, setValueDebounced, updatePosition])

  if (!element) {
    return [null, null] as const
  }

  return [scrollPosition, updatePosition] as const
}
