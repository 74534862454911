import { IMenu } from '../components/Menu/Menu.types'
import { IMenuItem } from '../components/MenuItem/MenuItem.types'

export const setActiveMenuItem = (menuSection: IMenu, pathname: string) => {
  const findUrlMatch = (url: string): boolean => {
    if (!url) return false
    const menuItemPathName = url.startsWith('http') ? new URL(url).pathname : url
    if (menuItemPathName === '/') return pathname === '/'
    return pathname.startsWith(menuItemPathName)
  }

  const setItems = (items: IMenuItem[]) => {
    items.forEach((m: IMenuItem) => {
      m.isActive = findUrlMatch(m.url)
      if (m.items) setItems(m.items)
    })
  }

  setItems(menuSection.items)
}
