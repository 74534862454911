import { logger } from '@brand-console/utilities'
import { CardProps, DialogProps } from '@cart/ui'
import Typography from '@mui/material/Typography'
import { DetailedHTMLProps, FormHTMLAttributes } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { IAccountConfirmData } from '../AccountConfirmForm/AccountConfirmForm.types'

export interface TrialFormProps {
  children: React.ReactNode
  /** Executed before the form is submitted. If it returns false the form wont be submitted */
  beforeSubmit?: () => boolean | void
  /** Submits additional form after account profile form */
  onSubmit?: SubmitHandler<unknown>
  /**
   * Executed when an error occurs during the account confirmation form submission
   * @param error The error object
   * @param friendlyErrorMessage A user friendly error message
   * @param scope The scope of the error, ie user, organization, etc
   * @returns
   */
  onError?: (error: Error, friendlyErrorMessage: string, scope: string) => void
  /** Executed after the account form and onSubmit are completed without errors */
  onComplete?: (data: IAccountConfirmData) => void
  formProps?: DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>
  formTitle?: string
  errorComponent?: React.ReactNode
  modal?: boolean
  log?: typeof logger
  cardProps?: CardProps
  dialogProps?: Partial<DialogProps>
   /**
   * If given will hide the form behind a loading animation until it triggers the resolved function.
   * This only applies to the form passed in the children prop, not the account form
   * @param data If called after account submit the form data will be passed, otherwise on page load the form default values will be passed
   * @param resolved Call this is the form is ready to be shown. It will remove the loading animation.
   */
   formTransitionIn?: (data: IAccountConfirmData, resolved: () => void) => void
}
