export enum DatePickerType {
  DATE_PICKER,
  TIME_PICKER,
  DATE_TIME_PICKER,
  RANGE_PICKER,
}

interface RangeLabels {
  start: string
  to: string
  end: string
}

export interface DatePickerProps {
  /**
   * CSS class to be applied to the element. Used to extend the style of the component via styled components.
   */
  className?: string
  /**
   * Adds an initial value to the date picker.
   */
  defaultValue?: Date
  /**
   * Disables the date picker.
   */
  disabled?: boolean
  /**
   * Unique identifier for the date picker.
   */
  id: string
  /**
   * The label of the date picker.
   */
  label?: string
  /**
   * The labels used for the range picker.
   */
  rangeLabels?: RangeLabels
  /**
   * Prevents the user from changing the value of the date picker.
   */
  readOnly?: boolean
  /**
   * Determines if the date picker is required.
   * @default false
   */
  required?: boolean
  /**
   * The type of the date picker. Defaults to `DATE_PICKER`.
   * @default DatePickerType.DATE_PICKER
   */
  type?: DatePickerType
}
