import MuiCardMedia from '@mui/material/CardMedia'
import React, { FC, useState } from 'react'

import { CardMediaProps } from './card-media.types'

export const fallbackImage = '/CardMedia-Fallback.svg'

export const CardMedia: FC<CardMediaProps> = ({
  component = 'img',
  height = 140,
  children,
  image,
  src,
  onError,
  ...props
}) => {
  const [url, setUrl] = useState(image || src)
  const [rootTag, setRootTag] = useState<React.ElementType<any>>(component)

  const handleImageError: React.ReactEventHandler<HTMLImageElement> = (event) => {
    setUrl(fallbackImage)
    setRootTag('img')
    if (onError) onError(event)
  }

  return (
    <MuiCardMedia
      height={height}
      component={rootTag}
      image={url}
      onError={handleImageError}
      {...props}
    >
      {children}
    </MuiCardMedia>
  )
}
