import { Business, Organization } from './common.types'
import { initializeStore } from './lib/store/initializer.store'

type InitializedData = Partial<Awaited<ReturnType<typeof initializeStore>>>

type User = Required<Pick<InitializedData, 'firstName' | 'lastName' | 'email' | 'phone'>>
export type CartAuthUser = User & {
  organizations: Organization[]
  onLogout: (options: OnLogoutProps) => Promise<void>
  setUser: StoreStateType['setUser']
  userId: InitializedData['userId']
  auth0Id: InitializedData['auth0Id']
  company: InitializedData['company']
  createdAt: InitializedData['createdAt']
  fullName: string
}

export type StoreStateType = InitializedData & {
  setUser: (user: User) => void

  organizations: Organization[]
  currentOrganization?: Organization
  setOrganization: (organization: Organization, selectedBusiness?: Business) => void

  currentBusiness?: Business
  setBusiness: (organization: Organization, business: Business) => void

  setCurrentContext: (organization, business) => void

  initialDataLoaded: boolean
  fetchInitialData: () => void
  setInitialData: (data: InitializedData) => void

  isLoadingInitialData: boolean
  setIsLoadingInitialData: (isLoading: boolean) => void

  roleName?: string | null
}

export interface OnLogoutProps {
  returnUrl?: string
  skipEndSession?: boolean
  skipClearLocalStorage?: boolean
  skipAuth0Logout?: boolean
  callback?: () => void
}
