import { DialogProps as MUIDialogProps } from '@mui/material'

export interface DialogProps extends MUIDialogProps {
  onClose?: (event: object, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick') => void
  /** Note that width and maxWidth are the same functionally, the difference being that setting width will also add the fullWidth property. */
  width?: DialogProps['maxWidth']
  /** If `true`, the close button is not rendered. */
  hideCloseButton?: boolean
}

export interface IDialogContext {
  onClose?: DialogProps['onClose']
  hideCloseButton?: DialogProps['hideCloseButton']
}
