import { Radio as MuiRadio } from '@mui/material'
import React, { FC } from 'react'
import { useController } from 'react-hook-form'

import formMethods from '../../framework/forms/form-methods'
import { RadioProps } from './radio.types'

/** Standalone Radio component. Do not use with RadioGroup */
export const Radio: FC<RadioProps> = ({ defaultValue, methods, id, rules, ...props }) => {
  const defaultMethods = formMethods()
  const { register, control } = methods || defaultMethods

  const registerField = register(id, rules)

  const {
    field: { onChange },
  } = useController({
    ...((defaultValue && { defaultValue }) as any),
    control,
    name: id,
    rules,
  })

  const onChangeEvent = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (props.onChange) props.onChange(e, checked)
    onChange(e)
  }

  return <MuiRadio id={id} {...registerField} {...props} onChange={onChangeEvent} />
}
