import { MenuItem } from '../MenuItem/MenuItem'
import { IMenuItem } from '../MenuItem/MenuItem.types'
import { SubMenu } from '../SubMenu/SubMenu'

export const MenuRenderer = ({ item }: { item: IMenuItem }) => {
  if (item.hidden) return null

  return item.items ? (
    <SubMenu submenu={item} level={0} />
  ) : (
    <MenuItem item={item} />
  )
}
