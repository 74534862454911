const UNIT_SECONDS = 1000
const UNIT_MINUTES = UNIT_SECONDS * 60
const UNIT_HOURS = UNIT_MINUTES * 60
const UNIT_DAYS = UNIT_HOURS * 24
const UNIT_WEEKS = UNIT_DAYS * 7
const UNIT_MONTHS = UNIT_DAYS * 28
const UNIT_YEARS = UNIT_DAYS * 365

export enum TimeUnits {
  Seconds = UNIT_SECONDS,
  Minutes = UNIT_MINUTES,
  Hours = UNIT_HOURS,
  Days = UNIT_DAYS,
  Weeks = UNIT_WEEKS,
  Months = UNIT_MONTHS,
  Years = UNIT_YEARS,
}
