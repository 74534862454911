import { Sidebar } from 'react-pro-sidebar'
import tw, { css, styled } from 'twin.macro'

export const StyledSidebar = styled(Sidebar)(({ collapsed }: { collapsed: boolean }) => [
  tw`[z-index: 1] left-0 h-full bg-monochrome-200 py-2 px-2 transition-all ease-in-out sm:[max-width: 250px]`,
  tw`sm:[min-width: 250px]`,
  tw`bg-monochrome-50 text-monochrome-900`,
  css`
    &.ps-collapsed {
      ${tw`px-0 lg:px-2 mobile:[margin-left: -1px]`}
      & .ps-submenu-content {
        & > ul {
          ${tw`my-2`}
        }
        & .ps-menu-button {
          ${tw`mx-2`}
        }
      }
    }
    .icon-active {
      .ps-menu-icon {
        ${tw`text-primary-700`}
      }
    }
    & .ps-submenu-content {
      ${tw`fixed bg-monochrome-50 text-sm`}
      & li {
        & a {
          ${!collapsed ? tw`!pl-11` : ''}
        }
        /** Add extra padding to nested submenus.
         * This ensures that the highlighted items spans whole width of sidebar. */
        &.ps-submenu-root .ps-submenu-content .ps-menu-button {
          ${!collapsed ? tw`!pl-18` : ''}
        }
      }
      & .leftnav-group-header {
        ${tw`mt-0 pl-11 leading-8`}
      }
      & .ps-menu-root {
        & > a {
          ${tw`pt-0 text-sm`}
        }

        &:not(:last-of-type) > ul > li:last-child {
          ${tw`pb-0`}
          &:after {
            display: none;
          }
        }
      }
      & .ps-menu-button {
        ${tw`!h-8`}
      }
    }
    & .leftnav-group-header {
      ${tw`mt-6 mb-0 overflow-hidden text-ellipsis pl-4 text-xs text-monochrome-500 uppercase leading-none tracking-wider`}
    }
    & .ps-menu-root {
      & > ul {
        ${tw`pt-2`}
      }
      &:not(:last-of-type) > ul > li:last-child {
        ${tw`relative pb-2`}
        &:after {
          content: '';
          ${tw`[height: 1px] absolute left-3 right-3 bottom-0 bg-monochrome-300`}
        }
      }
    }
    & .ps-menu-icon {
      ${tw`mr-1 text-monochrome-900/60`}

      &.ps-open,&.ps-active {
        ${tw`text-primary-700`}
      }
    }
    & .ps-menuitem-root {
      ${tw`leading-5`}
    }
    & .ps-menu-button {
      ${tw`!(h-11 pl-1 pr-5)`}
      &[data-level='0'] {
        ${tw`text-base`}
      }
      &.ps-open,
      &.ps-open:hover {
        ${tw`font-bold bg-[unset]`}
      }
      &.ps-active {
        ${tw`text-primary-700`};
      }
      &.ps-active,
      &:hover,
      &.ps-active:hover {
        ${tw`rounded bg-monochrome-200`}
      }
    }
  `,
])
