import { IFormatPriceOptions } from './formatPrice.types'

/**
 * Returns a formatted price string
 * @param price - The number to be formatted as a price.
 * @param options - Options for the formatting.
 * @returns Formatted price.
 */
export const formatPrice = (price: number, options?: IFormatPriceOptions): string => {
  const currency = options?.currency || 'USD'
  const locale = options?.locale || 'en-US'
  const output = new Intl.NumberFormat(locale, { style: 'currency', currency })
    .format(price)
    .replace(/\s/g, '')
  return output
}
