/**
 * This function injects a <script> tag into the page
 *
 * @author Glauber Rodger
 * @param  {string} src the url of the script to inject
 * @returns HTMLScriptElement
 */
export const addScript = (src: string): HTMLScriptElement => {
  const container = document.getElementsByTagName('head')[0]
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = src
  container.appendChild(script)
  return script
}
