import { LinkProps as MuiLinkProps } from '@mui/material/Link'

export interface LinkProps extends MuiLinkProps {
  /**
   * The component to render as. Defaults to `a` if `href` is provided, otherwise `button`.
   */
  component?: 'a' | 'button'
  /**
   * The link to the page
   */
  href?: string
  /**
   * Same as `href`, but for use with `react-router`'s `Link` component.
   */
  to?: string
}
