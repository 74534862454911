import React, { ReactNode } from 'react'

export interface AutocompleteOption {
  id?: number | string
  value: string
  [x: string]: unknown | string | boolean
}

export interface AutocompleteProps
  extends Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'onChange'
  > {
  /**
   * Content to be rendered when no options are available.
   */
  blankState?: string | ReactNode | ((query: string) => ReactNode)
  /**
   * CSS class to be applied to the element.
   */
  className?: string
  /**
   * Prepend the component with an icon.
   */
  icon?: React.ElementType
  /**
   * Function to be called to retrieve the options.
   */
  fetchOptions?: (search: string) => Promise<AutocompleteOption[]>
  /**
   * Contextual content to be rendered with the component.
   */
  helperText?: string
  /**
   * Autocomplete unique identifier.
   */
  id: string
  /**
   * Autocomplete label.
   */
  label?: string
  /**
   * Action to be performed when an autocomplete option is selected.
   */
  onChange?: (item: AutocompleteOption) => void
  /**
   * Allow custom rendering of the options.
   */
  optionRender?: (item: AutocompleteOption, query: string, selected: boolean) => React.ReactNode
  /**
   * Array of autocomplete options.  If passed, the `fetchOptions` property is ignored.
   */
  options?: AutocompleteOption[]
  /**
   * Autocomplete placeholder.
   */
  placeholder?: string
  /**
   * Determines if the field is required.
   */
  required?: boolean
  /**
   * Rules to be applied to the input.
   */
  rules?: Record<string, any>
}

export interface IStyledComboboxInput extends Record<string, any> {
  $hasError: boolean
  $hasIcon: boolean
  [x: string]: unknown
}
