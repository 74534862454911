import { useEffect, useRef, useState } from 'react'
import { useDebounce } from 'rooks'

import { RectAreEqual } from './lib'

/**
 * A hook that observes changes in the size of a given target element using the ResizeObserver API.
 */
export function useResizeObserver(
  targetElement: HTMLElement | null,
  onResize?: (rect: DOMRectReadOnly | null) => any,
) {
  const [contentRect, setContentRect] = useState<DOMRectReadOnly | null>(null)
  const resizeObserver = useRef<ResizeObserver | null>(null)

  const handleResize = useDebounce((rect) => {
    if (!RectAreEqual(rect, contentRect)) {
      setContentRect(rect)
      onResize?.(rect)
    }
  }, 100)

  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      handleResize(entries[0].contentRect)
    })

    if (targetElement && resizeObserver.current) {
      resizeObserver.current.observe(targetElement)
    }
    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect()
      }
    }
  }, [handleResize, targetElement])

  return [contentRect] as const
}
