import { InputBaseProps } from '@mui/material/InputBase/InputBase'
import {
  BaseTextFieldProps as MuiTextFieldProps,
  TextFieldClasses,
  TextFieldProps,
} from '@mui/material/TextField'
import { ReactNode } from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types'

export interface InputProps extends MuiTextFieldProps {
  id: string
  classes?: Partial<TextFieldClasses>
  /** If true, the input will no longer be editable by the user. */
  readOnly?: boolean
  /** An interactable element added to the start of the input */
  startAdornment?: ReactNode
  /** An interactable element added to the end of the input */
  endAdornment?: ReactNode
  rules?: Record<string, any>
  onChange?: InputBaseProps['onChange']
  methods?: UseFormReturn<any>
}

export type VariantType = TextFieldProps['variant']
