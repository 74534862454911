import React, { FC } from 'react'

import { LinkComponentType } from './linkify.types'

const Link = (LinkComponent: LinkComponentType, text: string, href = '', key = '') =>
  React.createElement(
    LinkComponent,
    {
      key: key || text,
      href: href || text,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    text,
  )

/**
 * Function to replace plain text hyperlinks or markdown style links with clickable links.
 *
 * @param {string} text - The plain text to scan for hyperlinks.
 * @param {React.ComponentType} [LinkComponent='a'] - The component to be used instead of the default `a` tag.
 * @return {Array} An array of components (strings or links) representing the input text with hyperlinks replaced by clickable links.
 *
 * @example
 * const result = linkify("Visit ui.cart.com at https://ui.cart.com");
 * console.log(result);
 * // Output: [
 * //   "Visit ui.cart.com at ",
 * //   <a href="https://ui.cart.com">https://ui.cart.com</a>
 * // ]
 */
export const linkify = (text: string, LinkComponent: LinkComponentType = 'a') => {
  if (!text) return []

  const urlRegex = /(https?:\/\/[^\s)]+|\[(?:[^\]]+)\]\((?:[^)]+)\))/g
  return (
    text
      .split(urlRegex)
      .map((part) => {
        if (!part) return part

        // Markdown link
        if (part.match(/^\[.*\]\(.*\)$/)) {
          const matches = /^\[(.*)\]\((.*)\)$/.exec(part)
          if (!matches) return part

          const [linkText, linkUrl] = matches.slice(1)
          return Link(LinkComponent, linkText, linkUrl, part)
        }
        // Normal Link
        if (part.match(urlRegex)) {
          return Link(LinkComponent, part)
        }

        return part
      })
      // Trim
      .filter((str) => str !== '')
  )
}

export interface LinkifyProps {
  text: string
  LinkComponent?: LinkComponentType
}
export const Linkify: FC<LinkifyProps> = ({ text, LinkComponent = 'a' }) => {
  return <>{linkify(text, LinkComponent)}</>
}
