import { ApolloError } from '@apollo/client'
import { OrganizationUsersInviteMutation } from '@brand-console/generated-graphql-hooks'
import { OrgRoles } from '@brand-console/types'

interface BaseInviteUserProps {
  orgId: number
  role: string
  emails: string
  onCompleted?: (data: OrganizationUsersInviteMutation) => void
  onError?: (error: ApolloError) => void
}

export interface OrgInviteUserProps extends BaseInviteUserProps {
  businessId?: number
  role: OrgRoles
}

export interface BusinessInviteUserProps extends BaseInviteUserProps {
  businessId: number
}

export type InviteUserProps = OrgInviteUserProps | BusinessInviteUserProps
