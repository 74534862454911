/**
 * Transforms a date into month/day/year format
 * @param date - date in INTL format
 * @returns date - date in 2 value format
 */

const undefinedDate = '(BLANK DATE)'

export function formatDate(date: string): string {
  if (!date || date.length === 0) return undefinedDate
  return new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  }).format(Date.parse(date))
}

export function formatDateFull(date: string): string {
  if (!date || date.length === 0) return undefinedDate

  const dateString = `${new Intl.DateTimeFormat('en-US', {
    timeStyle: 'short',
  }).format(Date.parse(date))} ${new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  }).format(Date.parse(date))}`

  return dateString
}

export function getDate(): string {
  return new Date().toLocaleDateString()
}
