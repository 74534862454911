import { SelectProps as MuiSelectProps } from '@mui/material/Select'
import { ReactElement } from 'react'

import { SelectItem } from './select-item/select-item'

export interface SelectProps extends MuiSelectProps {
  children: ReactElement<typeof SelectItem>[]
  defaultValue?: string | string[]
  id: string
  label: string
}
