import { IMenu } from '../../Menu/Menu.types'
import { McmMenuIds, McmOrdersUrls } from './McmMenu.types'

const consoleUrl = process.env.NX_APP_ORIGIN
const mcmLegacyUrl = process.env.NX_MCM_LEGACY_URL

export const mcmBaseMenu = (baseUrl): IMenu[] => {
  return [
    {
      id: McmMenuIds.MCM,
      items: [
        {
          title: 'Dashboard',
          url: `${baseUrl}/`,
          icon: 'faGaugeHigh',
        },
        {
          title: 'Products',
          url: `${baseUrl}/products`,
          icon: 'faBoxTaped',
        },
        {
          title: 'Listings',
          url: `${baseUrl}/listings`,
          icon: 'faTag',
        },
        {
          title: 'Orders',
          url: McmOrdersUrls.BC,
          icon: 'faCartShopping',
          id: McmMenuIds.ORDERS,
        },
        {
          id: McmMenuIds.REPORTS,
          title: 'Reports',
          icon: 'faFileChartColumn',
          items: [
            {
              title: 'Profit by Product',
              url: `${baseUrl}/report/profit-by-product`,
            },
            {
              title: 'Profit by Channel',
              url: `${baseUrl}/report/profit-by-channel`,
            },
            {
              title: 'Buy Box',
              url: `${baseUrl}/report/buybox`,
            },
            {
              title: 'Opportunities',
              url: `${baseUrl}/report/product-opportunities`,
              id: McmMenuIds.PRODUCT_OPPORTUNITIES,
              hidden: true
            },
          ],
        },
        {
          title: 'Tools',
          icon: 'faScrewdriverWrench',
          items: [
            {
              title: 'File Import',
              url: `${baseUrl}/file-import`,
            },
            {
              title: 'File History',
              url: `${baseUrl}/file-history`,
            },
            {
              title: 'Pricing Strategies',
              url: `${baseUrl}/pricing-strategies`,
            },
            {
              title: 'Vendors',
              url: `${baseUrl}/vendors`,
            },
          ],
        },
      ],
    },
    {
      id: McmMenuIds.MCM_SETTINGS,
      items: [
        {
          id: McmMenuIds.SETTINGS,
          title: 'Settings',
          icon: 'faGear',
          items: [
            {
              title: 'App Settings',
            },
            {
              title: 'Integrations',
              url: `${baseUrl}/integrations`,
            },
            {
              title: 'Fulfillment Centers',
              url: `${baseUrl}/fulfillment-centers`,
            },
            {
              title: 'Forecast Settings',
              url: `${baseUrl}/settings/forecast-settings`,
              hidden: true,
              id: McmMenuIds.FORECAST_SETTINGS
            },
            {
              title: 'API',
              url: `${baseUrl}/settings/api`,
              hidden: true,
              id: McmMenuIds.API,
            },
            {
              title: 'Packing Slips',
              url: `${mcmLegacyUrl}/orders/packingslipsettings.aspx`,
            },
            {
              title: 'Emails',
              url: `${baseUrl}/settings/emails`,
              hidden: true,
              id: McmMenuIds.EMAILS,
            },
            {
              title: 'Personal Settings',
            },
            {
              title: 'Profile',
              url: `${consoleUrl}/settings/profile?nav=mcm`,
            },
            {
              title: 'Security',
              url: `${consoleUrl}/settings/security?nav=mcm`,
            },
            {
              title: 'Organization Settings',
            },
            {
              title: 'Organization',
              url: `${consoleUrl}/settings/organization?nav=mcm`,
            },
            {
              title: 'Subscription',
              url: `${baseUrl}/subscription`,
            },
          ],
        },
        {
          title: 'Help',
          icon: 'faCircleQuestion',
          items: [
            {
              title: 'Knowledge Base',
              url: 'https://www.selleractive.com/support',
              newTab: true,
            },
            {
              title: 'Contact Us',
              url: 'https://www.selleractive.com/support/contact_support',
              newTab: true,
            },
            {
              title: 'Changelog',
              url: 'https://cart.canny.io/changelog?labels=marketplace-management',
              newTab: true,
            },
            {
              title: 'Feature Requests',
              url: 'https://cart.canny.io/feature-requests?category=marketplace-management',
              newTab: true,
            },
          ],
        },
        {
          title: 'Legacy',
          icon: 'faArchive',
          hidden: true,
          id: McmMenuIds.LEGACY,
          items: [
            {
              title: 'Pricing Manager',
              url: `${mcmLegacyUrl}/inventory/pricingmanager.aspx`,
            },
          ],
        },
        {
          title: 'Admin',
          icon: 'faPencil',
          hidden: true,
          id: McmMenuIds.ADMIN,
          items: [
            {
              title: 'Impersonate',
              url: `${baseUrl}/admin/impersonate`,
            },
            {
              title: 'Account Details',
              url: `${baseUrl}/admin/account-details`,
            },
            {
              title: 'Child Accounts',
              url: `${baseUrl}/admin/child-accounts`,
            },
            {
              title: 'Bundle Cost Auto-Calc',
              url: `${baseUrl}/admin/bundle-auto-calculate`,
            },
          ],
        },
      ],
    },
  ] as IMenu[]
}
