// https://my.americommerce.com/api/accounts/list
export interface StorefrontAccountsList {
  accountData: StorefrontAccountData
  provisioningAccounts: StorefrontProvisioningAccount[]
}

export interface StorefrontAccountData {
  accountNotes: string
  defaultTerms: unknown
  doNotAutoProcessPayments: boolean
  masterAccountId: number
  masterAccountName: string
  quickbooksCustomerId: string
}

export interface StorefrontProvisioningAccount {
  accountName: string
  bandwidthAllowance: unknown
  bandwidthUsage: unknown
  cartBusinessId: number
  id: number
  lockDownStatus: string
  masterAccountId: number
  paymentsRate: unknown
  planLevel: string
  productAllowance: unknown
  productCount: number
  sites: StorefrontSite[]
  storageAllowance: unknown
  storageUsage: number
  storeCount: unknown
}

export interface StorefrontSite {
  ameriCommerceVersion: string
  dnsStatus: string
  id: number
  isTrial: boolean
  name: string
  primaryDomain: string
  provisionDate: string
  sharedDomain: string
  siteStatus: string
  sslDomain: string
  sslType: string
}
