import { Switch } from '@headlessui/react'
import tw, { css, styled } from 'twin.macro'

import { ToggleButtonProps, ToggleProps } from './toggle.types'

export const StyledToggleWrapper = styled.div(() => [tw`inline-flex items-center`])

export const StyledSwitch = styled(Switch)(({ checked, variant, labelPosition }: ToggleProps) => [
  checked ? tw`bg-primary-700` : tw`bg-monochrome-600`,
  tw`relative inline-flex w-full items-center rounded-full px-1 h-[30px]`,
  labelPosition === 'inside' && tw`justify-end`,
  labelPosition === 'inside' && checked && tw`justify-start`,
  labelPosition === 'outside' && tw`w-[56px]`,
  variant === 'small' && tw`px-0.5 w-[30px] h-[16px]`,
])

export const StyledToggleButton = styled.span(
  ({ checked, variant, labelPosition }: ToggleButtonProps) => [
    tw`inline-block transform rounded-full bg-white transition-all h-[24px]`,
    labelPosition === 'inside' && tw`absolute left-1 top-[3px]`,
    checked &&
      labelPosition === 'inside' &&
      css`
        /* toggle button is 24px, plus 4px from end = 28px */
        left: calc(100% - 28px);
      `,
    checked && labelPosition === 'outside' ? tw`translate-x-[24px]` : tw`translate-x-0`,
    variant === 'medium' && tw`w-[24px]`,
    variant === 'small' && [tw`w-[12px] h-[12px]`, checked && tw`translate-x-4`],
  ],
)

export const StyledOuterLabelText = styled.div(() => [tw`ml-3`])

export const StyledInnerLabelText = styled.span(({ checked }: { checked: boolean }) => [
  tw`text-white transition-all`,
  checked ? tw`ml-3 mr-8` : tw`mr-3 ml-8`,
])

export const StyledAriaSpan = styled.span(() => [tw`sr-only`])
