import { useCallback, useEffect } from 'react'

export const useBeforeUnload = (confirmationMessage: string, confirmCondition?: () => boolean) => {
  const onBeforeUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (confirmCondition && confirmCondition()) {
        e.preventDefault()
        e.returnValue = confirmationMessage
      }
    },
    [confirmationMessage, confirmCondition],
  )

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [onBeforeUnload])

  return { onBeforeUnload }
}
