import { BreadcrumbsProps as MuiBreadcrumbsProps } from '@mui/material/Breadcrumbs'
import { ReactNode } from 'react'

export interface BreadcrumbsItemProps {
  /**
   * The link to the page
   */
  href?: string
  /**
   * The label of the link
   */
  text: string
}

export interface BreadcrumbsProps extends MuiBreadcrumbsProps {
  /**
   * Items to be displayed in the breadcrumbs. Either `items` or `children` can be used.
   */
  items?: BreadcrumbsItemProps[]
  /**
   * If customization is necessary, you can use the `children` prop to render your own breadcrumbs.
   * However, for simplicity and adherence to Graphene standards, it is recommended to use the
   * `items` prop instead.
   */
  children?: ReactNode
}
